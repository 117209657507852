<template>
  <div class="orders" v-if="loaded">
    <div class="d-flex justify-content-between align-items-center">
      <div class="main-title">
        <h6 class="mb-0">Pending Approval - Purchases</h6>
      </div>
      <div class="actions" v-if="checked.length > 0">
        <button class="btn btn-success f-13" data-bs-toggle="modal" data-bs-target="#approveModal">Approve</button>
        <button class="btn btn-danger ms-1 f-13" data-bs-toggle="modal" data-bs-target="#rejectModal">Decline</button>
      </div>
    </div>
    <div class="orders-body mt-2">
      <div class="card b-card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="tb-sn"></th>
                  <th>Action</th>
                  <th>Purchase No</th>
                  <th>Requestor</th>
                  <th>Request Date</th>
                  <th>Supplier</th>
                  <th>Total</th>
                  <th>Business Purpose</th>
                  <!-- <th>Status</th> -->
                </tr>
              </thead>
              <tbody v-if="orders && orders.orders.length > 0">
                <tr v-for="(order, index) in orders.orders" :key="index + '_' + order['order_id']">
                  <td>
                    <input type="checkbox" @change="addChecked(order.order_id)" />
                  </td>
                  <td>
                    <!-- <router-link
                      :to="'order/' + order.order_id"
                      class="btn-view"
                      v-if="order.merchant != 'Airtime'"
                    >
                      <i class="fas fa-eye"></i>
                    </router-link>
                    <router-link
                      v-else
                      :to="'bill/' + order.order_id"
                      class="btn-view"
                    >
                      <i class="fas fa-eye"></i>
                    </router-link> -->

                    <router-link :to="'order/' + order.order_id" class="btn-view" v-if="order.merchant == 'Konga' || order.merchant == 'Jumia'">
                      <i class="fas fa-eye"></i>
                    </router-link>
                    <router-link v-else :to="'bill/' + order.order_id" class="btn-view">
                      <i class="fas fa-eye"></i>
                    </router-link>
                  </td>
                  <td>{{ order.order_no }}</td>

                  <td>{{ order.order_by_name }}</td>
                  <td>
                    {{ formatDateTime(order.date) }}
                  </td>
                  <td>{{ order.merchant }}</td>
                  <td>
                    <!-- {{ order.merchant == "Jumia" ? "₦" : "$" }} -->
                    ₦{{ formatPrice(order.total) }}
                  </td>
                  <td>
                    {{ order.business_purpose ? order.business_purpose.slice(0, 50) : '' }}
                    {{ order.business_purpose ? (order.business_purpose.length > 50 ? '...' : '') : '' }}
                  </td>

                  <!-- <td>
                    <span
                      class="badge badge-success"
                      v-if="order.approval_status == 1"
                      >approved</span
                    >
                    <span class="badge badge-primary" v-else>pending</span>
                  </td> -->
                </tr>
              </tbody>
              <NoData :data="{ colspan: 9, message: 'No requests to approve' }" v-else></NoData>
            </table>
          </div>
          <nav aria-label="Page navigation example" v-if="orders && orders.orders.length > 0">
            <ul class="pagination justify-content-end">
              <li class="page-item" v-if="pagination.current != 1" :class="pagination.current == 1 ? 'disabled' : ''">
                <a class="page-link" href="#" @click.prevent="getOrders(pagination.current - 1)" tabindex="-1" aria-disabled="true">Previous</a>
              </li>
              <li class="page-item" v-for="(item, index) in pagination.pagLimit" :key="index" :class="item == pagination.current ? 'active' : ''">
                <a v-if="item <= pagination.last" class="page-link" @click.prevent="getOrders(item)" href="#">{{ item }}</a>
              </li>
              <li class="page-item" v-if="pagination.last > pagination.pagLimit">
                <select @change="nextPage($event)" class="select-page-link" :class="pagination.current > pagination.pagLimit ? 'active' : ''">
                  <option value="">...</option>
                  <option :value="item" v-for="(item, index) in pagination.last - pagination.pagLimit" :key="index">
                    {{ item + pagination.pagLimit }}
                  </option>
                </select>
              </li>
              <li class="page-item" v-if="pagination.current != pagination.last">
                <a class="page-link" @click="getOrders(pagination.current + 1)" href="#">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div class="modal fade" id="approveModal" tabindex="-1" aria-labelledby="approveModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="approveModalLabel">Approve Order</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="otpSent">
              <h6 class="otp-notify alert alert-info">
                OTP has been sent to your number that ends with ***{{ this.$store.state.user.user_phone.slice(-4) }}
                and to your email
              </h6>
              <form action="">
                <div class="form-group">
                  <label for="otp" class="f-14">OTP</label>
                  <input type="text" id="otp" class="form-control" v-model="otp" />
                  <span class="resendOtp cursor" @click.prevent="sendOTP()">Resend OTP</span>
                </div>
                <div class="form-group">
                  <label for="notes">Comment</label>
                  <textarea class="form-control" id="notes" v-model="notes" cols="30" rows="3"> </textarea>
                </div>
                <button class="btn btn-success" :disabled="loading" @click.prevent="approveOrder()">
                  <span v-if="!loading">Approve</span>
                  <span v-else>Loading...</span>
                </button>
              </form>
            </div>
            <div class="sendOtp d-flex flex-column align-items-center" v-else>
              <div class="so-i">
                <i class="fas fa-info"></i>
              </div>
              <p class="my-4">To approve this payment request, please generate an OTP</p>
              <button class="btn btn-success f-14" @click.prevent="sendOTP()">
                <span v-if="!loading">Send OTP</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="rejectModal" tabindex="-1" aria-labelledby="rejectModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="rejectModalLabel">Decline Purchase Request</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form action="">
              <div class="form-group">
                <label for="notes">Comment</label>

                <textarea class="form-control" id="notes" v-model="notes" cols="30" rows="5"> </textarea>
              </div>
              <button class="btn btn-danger" :disabled="loading" @click="rejectOrder()">
                <span v-if="!loading">Decline</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import NoData from '@/components/dashboard/noData.vue';
export default {
  components: { NoData },
  data() {
    return {
      amazon: {
        url: process.env.VUE_APP_PHP_PUNCHOUT_URL,
      },
      loading: false,
      orders: [],
      loaded: false,
      checked: [],
      pagination: {
        last: 2,
        current: 1,
        pagLimit: 6,
      },
      notes: '',
      otp: '',
      otpSent: false,
    };
  },
  methods: {
    rejectOrder() {
      this.loading = true;
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'approvalqueue/order/reject/' + this.$store.state.user.user_id,
          details: { order_ids: this.checked, notes: this.notes },
        })
        .then(() => {
          this.$store.commit('setLoader', false);
          this.loading = false;
          window.$('#rejectModal').modal('hide');
          window.SweetAlert('success', 'Purchase declined');
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.loading = false;
          window.$('#rejectModal').modal('hide');
          this.$store.dispatch('handleError', err);
        });
    },
    sendOTP() {
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'approvalqueue/order/approve-otp/' + this.$store.state.user.user_id,
          details: { order_ids: this.checked },
        })
        .then(() => {
          this.otpSent = true;
          this.$store.commit('setLoader', false);
          window.ToasterAlert('success', 'OTP sent successfully');
        })
        .catch((err) => {
          this.$store.dispatch('handleError', err);
        });
    },
    approveOrder() {
      this.loading = true;
      this.$store
        .dispatch('post', {
          endpoint: 'approvalqueue/order/approve/' + this.$store.state.user.user_id,
          details: {
            order_ids: this.checked,
            notes: this.notes,
            otp: this.otp,
          },
        })
        .then((resp) => {
          this.loading = false;
          window.$('#approveModal').modal('hide');
          window.SweetAlert('success', resp.data);
          window.location.reload();
        })
        .catch((err) => {
          this.loading = false;
          window.$('#approveModal').modal('hide');
          this.$store.dispatch('handleError', err);
        });
    },
    addChecked(id) {
      if (this.checked.includes(id)) {
        this.checked.splice(
          this.checked.findIndex((item) => item == id),
          1
        );
      } else {
        this.checked.push(id);
      }
      console.log(this.checked);
    },
    nextPage(no) {
      var cal = Number(no.target.value) + Number(this.pagination.pagLimit);
      this.getOrders(cal);
    },
    getOrders(page = 1) {
      this.$store.commit('setLoader', true);
      this.$store.dispatch('get', `approvalqueue/read/all/${this.$store.state.user.id}?page=${page}`).then((resp) => {
        this.$store.commit('setLoader', false);
        console.log(resp);
        this.loaded = true;
        this.orders = resp.data;
        this.pagination.current = page;
        this.pagination.last = Math.ceil(this.orders.total / this.orders.records_per_page);
      });
    },
  },
  created() {
    this.getOrders();
  },
};
</script>

<style scoped lang="scss">
.merchants-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .m-logo {
    // border: 1px solid gray;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 10px;
    width: 150px;
    background-color: transparent;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 15px;
    span {
      font-size: 13px;
    }
  }
}
// table thead tr th {
//   color: #5a5f7d;
//   background: #f8f9fb;
// }
.btn-add {
  font-size: 14px;
  border-radius: 0;
}
.btn-close {
  font-size: 14px;
}
.main-title .v-line {
  border: 1px solid #ced4da;
  background: #ced4da;
  height: 36px;
  width: 0.1px;
  margin: 0 30px;
}
.search {
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  height: 40px;
  width: 20em;
}
.t-badge .badge {
  margin-right: 3px;
}
.manage .form-group {
  margin-bottom: 10px;
}
</style>

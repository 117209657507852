export default {
  logout(state) {
    window.localStorage.removeItem('bsmp');
    state.user = {};
    state.token = null;
    window.location.href = '/login';
  },
  setLoader(state, data) {
    state.loader = data;
  },
  setIsBuyerDefault(state, data) {
    localStorage.setItem('bsmp-type', data);
    state.isBuyerDefault = data;
  },
  getIsBuyerDefault(state) {
    var result = localStorage.getItem('bsmp-type');
    state.isBuyerDefault = result;
  },
  setUser(state, data) {
    state.user = { ...data.profile, ...data.user, id: data.user.id };
    state.isSupplier = data.isSupplier;
    state.isBuyer = data.isBuyer;
    state.user.role_id = String(state.user.role_id).split(',');
    state.token = data.token;
    const ttl = 270000; //4.5 minutes in milliseconds, to get it multiply minute by 60000
    const now = new Date();
    const expiry = Number(now.getTime()) + Number(ttl);
    var saved = {
      user: state.user,
      token: state.token,
      isSupplier: state.isSupplier,
      expireAt: expiry,
    };
    var result = encodeURIComponent(JSON.stringify(saved));
    localStorage.setItem('bsmp', result);
  },
  getUser(state) {
    var data = localStorage.getItem('bsmp');
    data = JSON.parse(decodeURIComponent(data));
    if (data) {
      state.user = data.user;
      state.token = data.token;
      state.isSupplier = data.isSupplier;
      state.isBuyer = data.isBuyer;
      // this.commit("setConfiguration", state);
    } else {
      window.location = '/login';
    }
  },
  setdefaultSearchParamText(state, text) {
    state.defaultSearchParam = text;
  },
  getCart(state) {
    var stg = window.localStorage.getItem('bsmpCart');
    stg = JSON.parse(decodeURIComponent(stg));
    state.cart = stg ? { ...state.defaultCart, ...stg } : state.defaultCart;
  },
  addToCart(state, data) {
    var stg = window.localStorage.getItem('bsmpCart');
    stg = JSON.parse(decodeURIComponent(stg));
    data.data.quantity = 1;
    var exist = false;
    var msg = 'Product added to cart successfully';
    if (!stg) {
      state.cart[data['merchant']].push(data['data']);
    } else {
      state.cart = stg;
      state.cart[data['merchant']].forEach((element) => {
        if (element.data_id == data['data'].data_id) {
          exist = true;
          element.quantity += 1;
          msg = 'Cart updated successfully';
        }
      });
      if (!exist) {
        state.cart[data['merchant']].push(data['data']);
      }
    }
    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem('bsmpCart', result);
    window.ToasterAlert('success', msg);
  },
  removeFromCart(state, data) {
    state.cart[data['merchant']].splice(
      state.cart[data['merchant']].findIndex((item) => item.data_id == data['data'].data_id),
      1
    );
    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem('bsmpCart', result);
    window.ToasterAlert('success', 'Product removed from cart successfully');
  },
  reduceOrRemoveFromCart(state, data) {
    var msg = '';
    if (data['data'].quantity == 1) {
      state.cart[data['merchant']].splice(
        state.cart[data['merchant']].findIndex((item) => item.data_id == data['data'].data_id),
        1
      );
      msg = 'Product removed from cart successfully';
    } else {
      state.cart[data['merchant']].forEach((element) => {
        if (element.data_id == data['data'].data_id) {
          element.quantity -= 1;
          msg = 'Cart updated successfully';
        }
      });
    }

    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem('bsmpCart', result);
    window.ToasterAlert('success', msg);
  },
  setStateCart(state, data) {
    state.cart[data['merchant']] = data['data'];
    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem('bsmpCart', result);
  },
};
